<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title class="capitalize">Seguimiento presupuesto</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-tabs v-model="tab" centered>
      <v-tab> Gastos </v-tab>
      <v-tab> KPI Presupuesto </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <!-- GASTOS -->
        <v-row class="pt-5">
          <v-col>
            <!-- FORMULARIO -->
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-menu
                v-model="fecha"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Fecha"
                    placeholder="dd-mm-aaaa"
                    outlined
                    readonly
                    autocomplete="off"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    required
                  />
                </template>
                <v-date-picker
                  color="tway-violet"
                  v-model="date"
                  :first-day-of-week="1"
                  @input="fecha = false"
                  :min="new Date().toISOString()"
                />
              </v-menu>

              <v-select
                v-model="type"
                :items="types"
                item-value="id"
                item-text="concepto"
                :rules="[(v) => !!v || 'Requerido']"
                label="Tipo"
                required
                dense
                solo
              ></v-select>

              <v-select
                v-model="concept"
                :items="concepts"
                :rules="[(v) => !!v || 'Requerido']"
                label="Concepto"
                item-value="id"
                item-text="concepto"
                required
                dense
                solo
              ></v-select>

              <v-text-field v-model="expense" :rules="numberRule" label="Gasto" required></v-text-field>

              <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate"> Guardar </v-btn>

              <v-btn color="error" class="mr-4" @click="reset"> Limpiar </v-btn>
            </v-form>
          </v-col>
          <v-col>
            <!-- TABLA -->
            <v-card>
              <v-card-title>
                Items
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="dataTable.search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table :headers="dataTable.headers" :items="dataTable.desserts" :search="dataTable.search">
                <template v-slot:item.valor="{ item }">
                  <span>{{ formatCurrency(item.valor) }}</span>
                </template>
                <template v-slot:item.tipo="{ item }">
                  <span>{{ tipo(item.tipo) }}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <!-- KPI PRESUPUESTO -->
      <v-tab-item>
        <v-row class="pt-5">
          <v-col>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="name" :counter="10" :rules="nameRules" label="Nombre KPI" required></v-text-field>

              <v-text-field v-model="description" label="Descripción" required></v-text-field>

              <v-select
                v-model="select"
                :items="items"
                :rules="[(v) => !!v || 'Item is required']"
                label="Tipo KPI"
                required
                dense
                solo
              ></v-select>

              <v-text-field v-model="name" :counter="10" :rules="nameRules" label="Mínimo" required></v-text-field>

              <v-text-field v-model="name" :counter="10" :rules="nameRules" label="Máximo" required></v-text-field>

              <v-select
                v-model="select"
                :items="items"
                :rules="[(v) => !!v || 'Item is required']"
                label="Tramo"
                required
                dense
                solo
              ></v-select>
              <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate"> Guardar </v-btn>

              <v-btn color="error" class="mr-4" @click="reset"> Limpiar </v-btn>

              <v-btn color="warning" @click="back"> Volver </v-btn>
            </v-form>
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>
                KPI Agregados
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="dataTable.search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>

              <v-data-table :headers="dataTable.headers" :items="dataTable.desserts" :search="dataTable.search">
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import BudgetService from "@/services/pmo/budget/budgetService";

export default {
  data: () => ({
    fecha: null,
    date: null,
    minDate: "2019-07-04",
    maxDate: "2022-12-30",
    type: null,
    concept: null,
    expense: null,
    description: "",
    dataTable: {
      search: "",
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "concepto",
        },
        { text: "Tipo", value: "tipo" },
        { text: "Valor", value: "valor" },
      ],
      desserts: [],
    },

    tab: null,
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],

    numberRule: [(v) => !!v || "Favor ingrese un valor", (v) => !isNaN(v) || "Un valor entero menos de 10 dígitos"],

    select: null,
    types: ["tipo 1", "tipo 2", "tipo 3", "tipo 4"],
    concepts: ["concepto 1", "concepto 2", "concepto 3", "concepto 4"],
    items: ["item 1", "item 2", "item 3", "item 4"],
    checkbox: false,
  }),

  methods: {
    validate() {
      let formValidation = this.$refs.form.validate();

      if (this.fecha == null) {
        alert("Fecha es obligatorio");
      } else {
        if (formValidation) {
          let date = this.date;
          let type = this.type;
          let concept = this.concept;
          let expense = this.expense;

          BudgetService.saveExpense(date, type, concept, expense)
            .then(() => {
              this.reset();

              BudgetService.getAll()
                .then((r) => {
                  this.dataTable.desserts = r;
                })
                .catch((err) => console.dir(err));
            })
            .catch((err) => console.dir(err));
        }
      }
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    back() {
      this.$router.push({ name: "pmo-digital-budget" });
    },
    getAll() {
      BudgetService.getAll()
        .then((data) => {
          console.log("===== GET ALL ======");
          console.dir(data);
          this.dataTable.desserts = data;
        })
        .catch((err) => console.dir(err));

      BudgetService.getTypes()
        .then((data) => {
          console.log("==== TIPOS ======");
          console.dir(data);
          this.types = data;
        })
        .catch((err) => console.dir(err));

      BudgetService.getConcepts()
        .then((data) => {
          console.log("==== Conceptos ======");
          console.dir(data);
          this.concepts = data;
        })
        .catch((err) => console.dir(err));
    },
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      });
      return formatter.format(value);
    },
    tipo(value) {
      return value == "I" ? "Inicial" : "Actualizado";
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>
<style scoped>
.capitalize {
  color: #999999;
  text-transform: capitalize;
  font-size: 48px;
}
.resumen {
  width: 100%;
}
</style>
